import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element: Component }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated && state.auth.token !== null);

  if (isAuthenticated) {
    return <Component />;
  } else {
    return <Navigate to="/admin" />;
  }
};

export default ProtectedRoute;
